<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar title="MOBI-STOCKS" subtitle="Accueil">
      <f7-nav-right>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>
      </f7-nav-right>
    </f7-navbar>
 
    <!-- Page content-->
    <f7-block><center><img src="static/logo.png" alt="logo" height="100"></center></f7-block>
  
    <f7-block ></f7-block>

    <f7-block >
          <f7-button large fill raised  href="/cmde_liste/" >Saisie pour commande&nbsp;&nbsp;<f7-badge v-if='nbOrder > 0' color="red">{{nbOrder}}</f7-badge></f7-button>
    </f7-block>

    <f7-block >
          <f7-button large fill raised  href="/menu_inventaire/">Inventaire&nbsp;&nbsp; <f7-badge v-if='nbInvent > 0'  color="red">{{nbInvent}}</f7-badge></f7-button>
    </f7-block>

    <f7-block >
          <f7-button large fill raised href="/menu_synchro/">Synchronisation des données Locales</f7-button>
    </f7-block>

    <f7-block >
          <f7-button large fill raised login-screen-open="#my-login-screen">Identification / Connexion</f7-button>
          <!-- a revoir pour passer les param name et psw   -->
    </f7-block>
  </f7-page>
</template>
<script>

  import { OrderService } from "../service/Order_service";
  import { InventoryService } from "../service/Inventory_service";

  export default {
  data: function () {
    return {
      connexion: null,     // centraliser la connexion !!!
      connexion2: null, 
      nbOrder: 0,
      nbInvent: 0
    };
  },
  mounted () {
    this.connexion = new OrderService();
    this.countOrder();

    this.connexion2 = new InventoryService();
    this.countInventory();
    //this.$f7router.clearPreviousHistory();
  },  
  methods: {
    countOrder: async function() {
        this.nbOrder = await this.connexion.countOrder();
    },

    countInventory: async function() {
        this.nbInvent = await this.connexion2.countInventory();
    },
  },  

  }
</script>