<template>
  <f7-page name="settings">
    <f7-navbar title="MOBI-STOCKS" subtitle="Paramêtres" back-link="Back" >
      <f7-nav-right>
        <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" @click="openAnnulation"></f7-link>
      </f7-nav-right>  
    </f7-navbar>
    <f7-block-title>Liste des permissions</f7-block-title>
    <f7-list simple-list>
      <f7-list-item>
        <span>Caméra / Code à barre</span>
        <f7-toggle></f7-toggle>
      </f7-list-item>
      <f7-list-item v-if="notificationsSupported" >
        <span>Notifications</span>
        <f7-toggle :checked="notificationsEnabled" @change="notificationsEnabled = $event.target.checked" @toggle:change="onchange" ></f7-toggle>
      </f7-list-item>
      <f7-list-item  v-if="geolocSupported">
        <span>Géolocalisation</span>
        <f7-toggle></f7-toggle>
      </f7-list-item>
    </f7-list>    
  </f7-page>
</template>

<script>

export default {
  data() {
    return {
      notificationsSupported: false,
      geolocSupported: false,
      notificationsEnabled: false,
      serviceWorkerRegistation: null,
      subscription: null,
      firstcall: true,
      message: null
    };
  }, 
  created() {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      this.notificationsSupported = true
    }
  },

  mounted() {
    // Find out if the user has a subscription at the moment.
    // If so, update the enabled flag in data
    this.findSubscription()
    .then(sub => {
      if (sub === null) {
          console.log('no active subscription found on the client', sub);
          this.buttonDisabled = false;
          this.notificationsEnabled = false;
          this.firstcall = false;

      } else {
        console.log('Active subscription found', sub);
        // retrieve user info from API
        this.notificationsEnabled = true
        this.subscription = sub
       }
    })
  },

  methods: {
    onchange(){
      console.log('Toogle change in ', this.notificationsEnabled );
      if ( this.firstcall == false ){
        this.toggleSubscription();
      }
      this.firstcall = false;
    },

    openAnnulation (){
       // const app = this.$f7;
       //app.views.main.router.navigate( '/');
        this.$f7router.navigate('/');
    },
    
    toggleSubscription() {
      if (this.notificationsSupported) {
        // Find out if we need to create a subscription or delete it
                if (this.notificationsEnabled) {
                  debugger;

                  // ask for notification
                  this.createSubscription()
                  .then(sub => {
                      console.log('subscription created on the client', sub);
                      this.subscription = sub
                  })

                } else {
                    // Destroy subscription
                    console.log('Disable subscription');
                    if (this.subscription !== null) {
                        // destroy on the server
                        //return axios.post(`${process.env.VUE_APP_API_PATH}/subscription/delete`, { endpoint: this.subscription.endpoint})
                        // unsubscribe on the client
                        this.subscription.unsubscribe();
                         console.log('Disable subscription OK');
                    }
                }
      }
    },

    createSubscription() {
            console.log('ask for active service worker registration');
            if (this.serviceWorkerRegistation === null) {
                return navigator.serviceWorker.ready // returns a Promise, the active SW registration
                .then(swreg => {
                    this.serviceWorkerRegistation = swreg
                    return this.subscribe(this.serviceWorkerRegistation)
                })
            } else {
                return this.subscribe(this.serviceWorkerRegistation)
            }
    },

    getSubscription(swreg) {
      console.log('ask for available subscription');
      return swreg.pushManager.getSubscription()
    },

    subscribe(swreg) {
      console.log('create new subscription for this browser on this device');
      // create new subscription for this browser on this device
       const vapidPublicKey = 'BBAirg_W5c0Gc8_qTiuuZdoqWbTR2lISLdpp-NItWmjj0Txk-GsvWYBrdcxpvayFq6zvHipgxYxwut6t1FBZ4pM';
            const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey)
            // return the subscription promise, we chain another then where we can send it to the server
            return swreg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: convertedVapidPublicKey
            })
    },

    findSubscription() {
      console.log('Get active service worker registration');
      return navigator.serviceWorker.ready
      .then(swreg => {
        console.log('haal active subscription op');
        this.serviceWorkerRegistation = swreg
        return this.getSubscription(this.serviceWorkerRegistation)
      })
    },

        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/');

            const rawData = window.atob(base64);
            let outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },
  }

};
</script>