<template>
  <f7-page name="invt_liste">
    <f7-navbar>
      <f7-nav-left>
       <f7-link icon-ios="f7:arrow_left" icon-aurora="f7:arrow_left" icon-md="material:arrow_back" @click.stop="$f7router.navigate('/', {force: true, clearPreviousHistory: true})" ></f7-link>
      </f7-nav-left> 

      <f7-nav-title title="MOBI-STOCKS" subtitle="Liste inventaires non transmis" ></f7-nav-title> 

      <f7-nav-right>
       <f7-link icon-ios="f7:close" icon-aurora="f7:close" icon-md="material:close" @click.stop="$f7router.navigate('/', {force: true, clearPreviousHistory: true})" ></f7-link>
      </f7-nav-right>  
    </f7-navbar>
    <f7-block-title>&nbsp;</f7-block-title>

    <f7-fab style="transition-duration: 50ms;" position="right-top" slot="fixed" @click="openNewInventori">
      <f7-icon ios="f7:create_new_folder" aurora="f7:create_new_folder" md="material:create_new_folder"></f7-icon>
    </f7-fab>

    <f7-fab position="center-bottom" slot="fixed" color="green" @click="sendInventory">
      <f7-icon ios="f7:send" aurora="f7:send" md="material:send"></f7-icon>
    </f7-fab>

    <f7-block > </f7-block>
    <f7-block class="searchbar-hide-on-search">
      <p>Pour un nouvel inventaire, cliquez sur le Bouton <strong>Bleu</strong>.</br></br>Pour transmettre le ou les inventaires, sélectionnez les, puis cliquez sur le Bouton <strong>Vert</strong> d'envoi.</p>
    </f7-block>

<!-- <f7-list media-list> -->
<f7-list>
  <f7-list-item
    v-for="(link, index) in inventories"
    :key="index"
    :value="link.id"
    @click.stop="openInventory( link.id, link.num_invt )"
  >
    &nbsp;&nbsp;&nbsp;<f7-link color="red"  icon-f7="trash"  @click.stop="openSupprimer( index, link.id, link.num_invt )" ></f7-link>
    <div slot="title" style="white-space: normal"><label class="checkbox"  @click.stop="stopTheEvent"><input class="icon-checkbox" type="checkbox" :value="link.id" @change="onMovieChange"><i class="icon-checkbox"></i></label>&nbsp;&nbsp;&nbsp;{{link.num_invt}}</div>
    <div slot="title" style="white-space: normal">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{link.name_site}}</div>
    <div slot="after" style="white-space: normal">{{link.datecreat}}</br><center>{{link.timecreat}}</center></div>
  </f7-list-item>
</f7-list>

  </f7-page>
</template>

<script>

import { InventoryService } from "../service/Inventory_service";
import { debuglog } from 'util';

export default {
  data: function () {
    debugger;
    this.$f7.data.Invt_num_type = localStorage.getItem("Invt_num_type");
    return {
      connexion: null,     // centraliser la connexion !!!
      inventories: [],
      selection: [],
      num_type: this.$f7.data.Invt_num_type,
    };
  },

  mounted () {
    this.connexion = new InventoryService();
    this.refreshInventory( this.num_type );
  },  

  methods: {

    stopTheEvent ( e ){

    },

    openNewInventori() {
        const app = this.$f7;

        this.$f7.data.newInvt = true;
        this.$f7.data.currentInvt = 0;
        this.$f7.data.ifInvt = true;

        let  ReferencementOK = false;
        if (Array.isArray(app.data.referencement) && app.data.referencement.length) {
          // array exists and is not empty
          ReferencementOK = true;
        }

        if (ReferencementOK == false){
            app.dialog.alert('Vous n\'avez pas de référencement faite une synchronisation !');
            return;
        }

        // Effacer les lignes de commande précédentes
        this.$f7.data.tableLigne = [];

        localStorage.setItem('tableLigne', JSON.stringify(this.$f7.data.tableLigne));

        localStorage.setItem('newInvt', JSON.stringify(this.$f7.data.newInvt));
        localStorage.setItem('currentInvt', JSON.stringify(this.$f7.data.currentInvt));
        localStorage.setItem('ifInvt', JSON.stringify(this.$f7.data.ifInvt));

        // Positionner les valeurs par défaut DYNAMIQUE
        localStorage.setItem('dflt_session', "");

        localStorage.setItem('dflt_site', "");
        localStorage.setItem('dflt_site_name', "");
        localStorage.setItem('dflt_poste', "");

        app.views.main.router.navigate( '/article_liste/'); 
    },

    onMovieChange: function (e) {
      var self = this;
      var value = e.target.value;
      var selection = self.selection;
      if (e.target.checked) {
        selection.push(value);
      } else {
        selection.splice(selection.indexOf(value), 1);
      }
    },

    refreshInventory: async function( num_type) {
        var result = await this.connexion.getInventory(num_type);

        //results will contains no of rows deleted.
        /*
        console.log("Result: " + JSON.stringify(result));
        var count = result.length;
        console.log( "Count :" + count);
        */
       debugger;
      this.inventories = result;
    },  

    sendInventory: async function() {  
      debugger; 
      const app = this.$f7;

      var inventories = this.inventories;
      var tosend = [];
      var selection = this.selection;

      if (selection.length == 0 ){
          app.dialog.alert('Vous avez sélectionné aucun inventaire !');
          return;
      }

      // Ici faire boucle de remplissage
      for (let j = 0; j < selection.length; j += 1) {
          let numcde = selection[j]; 
          for (let i = 0; i < inventories.length; i += 1) {
            if ( inventories[i].id ==  numcde ){
   		        tosend.push(inventories[i]);
		          break;
	          }
          } 
      }
 
      debugger; 
      app.preloader.show();

      let st_login=  localStorage.getItem("glb_login");
      let st_mdp=  localStorage.getItem("glb_mdp");
      let thecall = this.$f7.data.site_url + "send_mobistk02.php";

      app.request.post(thecall, { login: st_login, mdp: st_mdp ,trame: tosend }  , (data) =>  {
            debugger;
            console.log(data);
            app.preloader.hide();

            let mydata= JSON.parse(data);
            let result = mydata[0].result;

            if (result == 'KO') {
                let thetext = mydata[0].erreur;
                app.dialog.alert('Erreur de connexion ! <br>'+ thetext );
            }
            else {

                app.dialog.alert('Transfert avec succès !',  () => {
                  debugger;
                  
                  // effacer les commandes puis refresh
                  for (let i = 0; i < tosend.length; i += 1) {
                    let id = tosend[i].id;
                    debugger;
                    var retour = this.connexion.removeInventory(id);
                  } 

                  //app.views.main.router.navigate( '/cmde_liste/');
                  app.views.main.router.refreshPage(); 
                });
            }

            return;
           }, (err) =>  {
               debugger;
               app.preloader.hide();
               app.dialog.alert('Erreur: réseau non disponible');
           });      
    },

    openSupprimer: function ( indexToDelete, id , myInventoryName) {
         debugger;
          var app = this.$f7;
          var tableInvts = this.inventories;
          var mytext = "Confirmez-vous la suppression de l'inventaire " + myInventoryName + " ?";
          // Rafraichis le tableau en local
          app.dialog.confirm(mytext,  () => {
            var result = this.connexion.removeInventory(id);
            // supprimer de la liste
            tableInvts.splice(indexToDelete, 1);
          });
    },


    openAnnulation (){
        const app = this.$f7;
        app.views.main.router.navigate( '/'); 
    },

    openInventory( myInventory, myInventoryName ){
      debugger;
      var mytext = "Confirmez-vous vouloir modifier la commande " + myInventoryName + " ?";

      const app = this.$f7;
      this.$f7.data.newInvt = false;
      this.$f7.data.currentInvt = 0;
      this.$f7.data.ifInvt = true;
      
      this.$f7.data.tableLigne = [];
      var theInventories = this.inventories;

      app.dialog.confirm(mytext,  () => {
        /*   trouver et prendre */
        for (let i = 0; i < theInventories.length; i += 1) {
          var theInventory = theInventories[i];
          var toto = theInventory['id'];

          if (toto == myInventory ) {
            /* reprend toutes les lignes de la commande */
            this.$f7.data.tableLigne = theInventory['lignes'].slice();

            this.$f7.data.dflt_session = theInventory['session'];

            this.$f7.data.dflt_site = theInventory['site'];
            this.$f7.data.dflt_site_name =  theInventory['name_site'];
            this.$f7.data.dflt_poste ="";
            this.$f7.data.currentInvt = myInventory;

            localStorage.setItem('newInvt', JSON.stringify(this.$f7.data.newInvt));
            localStorage.setItem('currentInvt', JSON.stringify(this.$f7.data.currentInvt));
            localStorage.setItem('ifInvt', JSON.stringify(this.$f7.data.ifInvt));

            localStorage.setItem('tableLigne', JSON.stringify(this.$f7.data.tableLigne));

            localStorage.setItem('dflt_session', this.$f7.data.dflt_session);
            localStorage.setItem('dflt_site', this.$f7.data.dflt_site);
            localStorage.setItem('dflt_site_name',  this.$f7.data.dflt_site_name);
            localStorage.setItem('dflt_poste',  this.$f7.data.dflt_poste);

            break;
          }
        }

        /* ouvrir */
        app.views.main.router.navigate( '/invt_ligne/');

      });

    },
    
  }
};
</script>
