import { connection } from "./jsstore_con";

export class InventoryService {

    constructor() {
         
        this.tableName = "tblInventory";
    }

    countInventory(){
        return connection.count({
            from: "tblInventory",
        })
    }

    countInventoryType (thetype){
        return connection.count({
            from: "tblInventory",
            where: {
                num_type: thetype
            },
        })
    }

    getInventory( thetype ) {
        return connection.select({
            from: "tblInventory",
            where: {
                num_type: thetype
            }
        })
    }

    addInventory(cde) {
        return connection.insert({
            into: "tblInventory",
            values: [cde],
            return: true
        })
    }

    getInventoryById(id) {
        return connection.select({
            from: "tblInventory",
            where: {
                id: id
            }
        })
    }

    removeInventory(id) {
        return connection.remove({
            from: "tblInventory",
            where: {
                id: id
            }
        })
    }

    clearInventory() {
        //debugger;
        return connection.clear("tblInventory")
    }

    updateInventoryById(id, updateData) {
        return connection.update({
            in: "tblInventory",
            set: updateData,
            where: {
                id: id
            }
        })
    }
}