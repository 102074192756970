import { connection } from "./jsstore_con";

export class ReferencementService {

    constructor() {
         
        this.tableName = "Referencement";
    }

    /* Referencement  */

    getReferencement() {
        return connection.select({
            from: "Referencement",
        })
    }

    groupbyCdts() {
        return connection.insert({
            into: "Referencement",
            return: true
        })
    }

    addReferencement(student) {
        return connection.insert({
            into: "Referencement",
            values: [student],
            return: true
        })
    }

	syncReferencement( app ) {
        debugger;
        app.preloader.show();

        let st_login=  localStorage.getItem("glb_login");
        let st_mdp=  localStorage.getItem("glb_mdp");

        if ( st_login == null || st_mdp == null){
            app.preloader.hide();
            app.dialog.alert('Erreur: Synchronisation non effectuée ! vérifiez votre identifiant de connexion');
            return;
        }
        let thecall = app.data.site_url + 'request_mobistk01.php?filename=REFERENCEMENT&login='+ st_login+ '&mdp=' + st_mdp;

        app.request.get(thecall, (data) =>  {
    
            //console.log(data);
            debugger;

            let mydata= JSON.parse(data);

            let idt = mydata.idt[0];
            if (  idt.result == 'KO' ){
                localStorage.setItem('referencement', JSON.stringify(""));
                localStorage.setItem('societe', JSON.stringify(""));
                localStorage.setItem('search', JSON.stringify(""));
                
                app.data.referencement=[];
                app.data.local_search=[];

                app.preloader.hide();
                app.dialog.alert('Erreur: Accès rejeté ! Vérifiez vos identifiants de connexion');
                return; 
            }


                let referencement = mydata.referencement;
                localStorage.setItem('referencement', JSON.stringify(referencement));

                let societe =  mydata.societe;
                localStorage.setItem('societe', JSON.stringify(societe));
            
                let search =  mydata.search;
                localStorage.setItem('search', JSON.stringify(search));

                //récupéré
                let local_referencement = localStorage.getItem("referencement");
                if ( local_referencement != null ) {  
                    app.data.referencement = JSON.parse(local_referencement);
                }
                else
                    app.data.referencement=[];
    
                let local_search = localStorage.getItem("search");
                if ( local_search != null ) {  
                    app.local_search = JSON.parse(local_search);
                }
                else
                    app.data.local_search=[];

                    /*  
                    return connection.insert({
                     into: "Referencement",
                    values: referencement,
                    return: true
                    })
                 */
                 /*
                     //  c'était bon avant la gestion d'erreur !
                     return connection.bulkInsert({    
                    into: "Referencement",
                     values: referencement
                    })
                    */
        
                connection.bulkInsert({ into: "Referencement", values: referencement }).then((value) =>  {
                    //console.log("DANEM  break point synchro 1");
                    app.preloader.hide();
                    app.dialog.alert('Synchronisation réussie');
                
                }, (err) =>  {
                    //console.log("DANEM  break point synchro 2");
                    app.preloader.hide();
                    app.dialog.alert('Erreur: Synchronisation non effectuée !');
                
                });
            
                return;

           }, (err) =>  {
               app.preloader.hide();
               app.dialog.alert('Erreur: réseau non disponible');
           });
	}    

    getReferencementById(id) {
        return connection.select({
            from: "Referencement",
            where: {
                id: id
            }
        })
    }


    getReferencementByRefInterne(id) {
        return connection.select({
            from: "Referencement",
            where: {
                referenceinterne: id
            }
        })
    }

    removeReferencement(id) {
        return connection.remove({
            from: "Referencement",
            where: {
                id: id
            }
        })
    }

    clearReferencement() {
        //debugger;
        return connection.clear( "Referencement")
    }

    updateReferencementById(id, updateData) {
        return connection.update({
            in: "Referencement",
            set: updateData,
            where: {
                id: id
            }
        })
    }

    /*  Order */
}