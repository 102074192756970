<template>
  <f7-page name="catalog">
    <f7-navbar title="MOBI-STOCKS"></f7-navbar>
     <f7-block-title>Référencement</f7-block-title>
    <f7-list>
      <f7-list-item
        v-for="(product) in products"
        :key="product.id"
        :title="product.designationinterne"
        :link="`/product/${product.referenceinterne}/`"
      ></f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>

import { ReferencementService } from "../service/referencement_service";

  export default {
    data: function () {
      return {
        products: [],
      };
    },
    methods: {
      refreshReferencement: async function() {
        this.products = await new ReferencementService().getReferencement();
      }     
    },
    mounted() {
      this.refreshReferencement();
    }
  };
</script>
